.product-button {
  text-align: center;
}

.product-button__icon {
  border: 2px solid rgba(0, 0, 0, 0.7) !important;
  /* color: black; */
  font-weight: bold;
  margin: 0 0.3rem;
  border-radius: 50%;
}

.product-button__icon[disabled] {
  border-color: rgba(0, 0, 0, 0.3)  !important;
}

.product-button__icon--small {
  width: 1.85rem;
  height: 1.85rem;
}

.modal-product-count {
  padding: 0.4em 0.5em;
  min-width: 2.1em;
  text-align: center;
}